






































import {Component, Vue} from 'vue-property-decorator';
import {getCourseByCoachIdAsync} from '@/api/course'
import {CourseDto} from '@/dto/CourseDto';

@Component({})
export default class CoachCourse extends Vue {
  private courses: any = {
    items: new Array<CourseDto>()
  }
  private loading = false;

  private async activated() {
    await this.getList()
  }

  private async getList(queryDate?: string) {
    this.loading = true
    const dt = ``
    const result = await getCourseByCoachIdAsync(dt, 1)
    this.courses = result;
    this.loading = false
  }
}
